import React, { useEffect } from 'react'

import SEO from '../../components/SEO'
import Layout from '../../components/Layout'
import Picture from '../../components/Picture'
import FormNewsletter from '../../components/Newsletter'
import VideoModal from '../../components/ModalVideo'
import VideoWithModal from '../../components/VideoWithModal'

import { ditoTrack } from '../../helpers/dito'

import {
  bannerTopoLg,
  bannerTopoMd,
  bannerTopoXs,
  cabedal,
  featureCabedal1,
  featureCabedal2,
  featureCabedal3,
  featureSola1,
  featureSola2,
  featureSola3,
  paraTodosCorredores,
  runningGuys,
  sola,
  thumbVideo,
} from '../../images/gelkinsei'

import * as S from './styles'
import { graphql } from 'gatsby'

const GelKinseiPage = () => {
  useEffect(() => {
    ditoTrack('acessou-gel-kinsei-blast')
  }, [])

  return (
    <Layout>
      <SEO title="GEL-KINSEI" />

      <VideoModal videoId="AKU2lxpg1MM" />

      <S.BannerTopo>
        <a
          href="https://www.asics.com.br/calcados?filtro_87=GEL-Kinsei-Blast&utm_source=lab&utm_medium=lp&utm_campaign=crm-lp-kinsei-blast&paged=1"
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
          <Picture
            xs={bannerTopoXs}
            md={bannerTopoMd}
            lg={bannerTopoLg}
            alt="Gel Kinsei"
          />
        </a>
      </S.BannerTopo>

      <S.AboutWrapper>
        <div className="item item--image">
          <Picture
            xs={runningGuys}
            md={runningGuys}
            lg={runningGuys}
            alt="Running Guys"
          />
        </div>
        <div className="item item--text">
          <h3>
            Tenha mais conforto e proteção
            <br />
            para aproveitar ao máximo a sua
            <br />
            corrida com o lançamento do
            <br />
            <span>GEL-KINSEI™ BLAST</span>
          </h3>
        </div>
      </S.AboutWrapper>

      <VideoWithModal
        classname="gel-kinsei"
        title="Conheça e viva a"
        subtitle="Energia que te move"
        thumb={thumbVideo}
      />

      <S.FeaturesWrapper>
        <S.FeatureBox>
          <div className="item">
            <img src={cabedal} alt="Cabedal" className="thumb-cabedal" />
          </div>
          <div className="item">
            <h2>
              Cabedal inspirado nas <br />{' '}
              <strong>armaduras de um samurai:</strong>
            </h2>

            <S.FeaturesList>
              <div className="feature feature1">
                <img src={featureCabedal1} alt="feature Cabedal" />
                <p>
                  <strong>Malha em ENGINEERED KNIT™</strong> que protege seu pé
                  em um ajuste seguro e confortável com suporte adicional no
                  meio do pé
                </p>
              </div>
              <div className="feature feature2">
                <img src={featureCabedal2} alt="feature Cabedal" />
                <p>
                  <strong>Placa de propulsão PEBAX™</strong> para redirecionar a
                  energia do corredor para frente e criar uma sensação de
                  impulsão e aumento de velocidade com mais estabilidade
                </p>
              </div>
              <div className="feature feature3">
                <img src={featureCabedal3} alt="feature Cabedal" />
                <p>
                  Design inspirado na herança japonesa e a fortaleza de
                  armaduras Samurai
                </p>
              </div>
            </S.FeaturesList>
          </div>
        </S.FeatureBox>

        <S.FeatureBox reverse>
          <div className="item">
            <img src={sola} alt="Sola" className="thumb-sola" />
          </div>
          <div className="item">
            <h2>
              Sola do tênis com
              <br />
              <strong>alta tecnologia:</strong>
            </h2>

            <S.FeaturesList>
              <div className="feature feature1">
                <img src={featureSola1} alt="feature Sola" />
                <p>
                  <strong>FLYTEFOAM BLAST™</strong> na entressola atuando como
                  uma mola responsiva, <strong>FLYTEFOAM™</strong> + para um
                  amortecimento leve e com maior durabilidade,{' '}
                  <strong>GEL™</strong> para mais proteção ao pé e absorção de
                  choque
                </p>
              </div>
              <div className="feature feature2">
                <img src={featureSola2} alt="feature Sola" />
                <p>
                  <strong>Solado desenvolvido</strong>
                  <br />
                  estrategicamente para mais aderência e durabilidade
                </p>
              </div>
              <div className="feature feature3">
                <img src={featureSola3} alt="feature Sola" />
                <p>
                  <strong>Formato da entressola</strong>
                  <br />
                  inspirado nas curvas de uma espada katana
                </p>
              </div>
            </S.FeaturesList>
          </div>
        </S.FeatureBox>
      </S.FeaturesWrapper>

      <S.OndeComprarWrapper>
        <S.OndeComprarContainer>
          <div className="item">
            <img
              src={paraTodosCorredores}
              alt="Feito para todos os corredores"
              className="img-mobile"
            />
            <S.OndeComprarTitle>
              Feito para{' '}
              <strong>
                todos <br /> os corredores
              </strong>
            </S.OndeComprarTitle>
            <S.OndeComprarText>
              Esse é um calçado mais estruturado e técnico, desenvolvido com
              tecnologias de ponta para entregar o máximo de conforto e
              responsividade aos corredores.
            </S.OndeComprarText>
            <S.OndeComprarText>
              É um modelo perfeito para treinos e provas de longa distância
              devido sua excelente durabilidade. Sua placa de propulsão PEBAX ™
              cria uma sensação de impulsão e energia transformando esse
              lançamento em um calçado diferenciado, moderno e com amortecimento
              avançado.
            </S.OndeComprarText>
            <S.OndeComprarSubTitle>Especificações:</S.OndeComprarSubTitle>
            <S.OndeComprarText>
              Drop = 10 mm (M)
              <br />
              Drop = 10 mm (F)
              <br />
              Tamanho da altura traseira/ dianteira = 30/20 (M)
              <br />
              Tamanho da altura traseira/ dianteira = 29/19 (F)
              <br />
              Peso = 295g (M)
              <br />
              Peso = 255g (F)
            </S.OndeComprarText>
          </div>

          <div className="item">
            <img
              src={paraTodosCorredores}
              alt="Feito para todos os corredores"
              className="img-desk"
            />
            <S.ButtonComprarWrapper>
              <S.ButtonComprar
                href="https://www.asics.com.br/calcados?filtro_87=GEL-Kinsei-Blast&utm_source=lab&utm_medium=lp&utm_campaign=crm-lp-kinsei-blast&paged=1"
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                comprar agora
              </S.ButtonComprar>
              <S.ButtonComprar
                href="https://api.whatsapp.com/send/?phone=551150568450&text&app_absent=0"
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                comprar pelo whatsapp
              </S.ButtonComprar>
            </S.ButtonComprarWrapper>
          </div>
        </S.OndeComprarContainer>
      </S.OndeComprarWrapper>

      <S.WrapperNewsletter>
        <S.ContentNewsletter>
          <h2>Cadastre seu e-mail para receber ofertas e novidades</h2>

          <S.FormNewsletter>
            <FormNewsletter isLP origem="lp-lab-kinsei" />
          </S.FormNewsletter>
        </S.ContentNewsletter>
      </S.WrapperNewsletter>
    </Layout>
  )
}

export default GelKinseiPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
