import styled from 'styled-components'

export const BannerTopo = styled.section`
  background-color: #afe2be;
  width: 100%;
`

export const AboutWrapper = styled.section`
  display: flex;
  width: 100%;

  @media (max-width: 767px) {
    flex-direction: column;
  }

  @media (min-width: 1800px) {
    margin: 0 auto;
    max-width: 1700px;
  }

  .item--image {
    width: 100%;

    @media (min-width: 1700px) {
      width: 60%;
    }
  }

  .item--text {
    background-color: #00746d;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    padding: 0 40px;
    position: relative;
    width: 100%;

    @media (max-width: 660px) {
      padding: 40px 20px;
      text-align: center;
    }

    @media (min-width: 992px) and (max-width: 1199px) {
      padding: 20px;
    }
  }

  h3 {
    font-size: 30px;
    font-style: italic;
    font-weight: 400;
    line-height: 30px;

    @media (max-width: 660px) {
      font-size: 22px;
      line-height: 25px;
    }

    span {
      color: #afe2be;
      font-weight: 700;
      line-height: 60px;

      @media (max-width: 660px) {
        line-height: 40px;
      }
    }
  }
`

export const FeaturesWrapper = styled.section`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 100px 0;

  @media (max-width: 767px) {
    padding: 100px 0 30px 0;
  }
`

export const FeatureBox = styled.section`
  align-items: center;
  display: flex;
  gap: ${({ reverse }) => (reverse ? '0' : '60px')};
  justify-content: center;
  flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};

  @media (max-width: 767px) {
    flex-direction: column;
  }

  h2 {
    color: var(--primary-color);
    font-size: 34px;
    font-weight: 400;
    line-height: 35px;

    @media (max-width: 767px) {
      font-size: 28px;
      line-height: 30px;
      margin: 20px 0;
      text-align: center;
    }
  }

  img {
    width: 270px;

    @media (max-width: 767px) {
      display: block;
      margin: 0 auto;

      &.thumb-cabedal {
        margin-left: 20px;
        width: 190px;
      }

      &.thumb-sola {
        width: 135px;
      }
    }
  }
`

export const FeaturesList = styled.div`
  margin: 30px 0;
  width: 550px;

  @media (max-width: 767px) {
    width: 100%;
  }

  .feature {
    align-items: center;
    display: flex;
    margin-bottom: 20px;

    @media (max-width: 767px) {
      flex-direction: column;
      text-align: center;
    }
  }

  img {
    margin-right: 30px;
    width: 150px;

    @media (max-width: 767px) {
      display: block;
      margin: 0 auto 20px auto;
    }
  }

  p {
    color: var(--primary-color);
    font-size: 19px;
    font-style: italic;
    line-height: 22px;
    width: 270px;
  }
`

export const OndeComprarWrapper = styled.section`
  background-color: #c8ebd2;
  padding: 60px 0;
  width: 100%;

  @media (max-width: 767px) {
    padding: 20px 0;
  }

  h2,
  h3,
  p {
    color: var(--primary-color);
  }
`

export const OndeComprarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1070px;
  position: relative;
  width: 100%;

  @media (max-width: 767px) {
    flex-direction: column;
    text-align: center;
  }

  .img-desk {
    width: 450px;

    @media (max-width: 767px) {
      display: none;
    }
  }

  .img-mobile {
    margin-bottom: 30px;
    width: 85%;

    @media (min-width: 767px) {
      display: none;
    }
  }
`

export const OndeComprarTitle = styled.h2`
  font-size: 40px;
  font-style: italic;
  font-weight: 400;
  line-height: 40px;

  @media (max-width: 767px) {
    font-size: 26px;
    line-height: 26px;
  }
`

export const OndeComprarSubTitle = styled.h3`
  font-family: 'ASICSFont3.0-BoldItalic';
  font-size: 26px;
  font-weight: bold;
  margin-top: 40px;
`

export const OndeComprarText = styled.p`
  font-size: 20px;
  font-style: italic;
  font-weight: 400;
  line-height: 27px;
  margin: 16px 0;
  width: 530px;

  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 20px;
    padding: 0 15px;
    width: 100%;
  }
`

export const ButtonComprarWrapper = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
`

export const ButtonComprar = styled.a`
  background-color: var(--primary-color);
  border-radius: 99999px;
  color: #fff;
  display: block;
  font-family: 'ASICSFont3.0-BoldItalic';
  font-size: 16px;
  margin: 10px 0;
  padding: 10px 0;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.3s ease;
  width: 265px;

  &:hover {
    background-color: #fff;
    color: var(--primary-color);
  }
`

export const WrapperNewsletter = styled.section`
  padding: 50px 0;
  width: 100%;

  @media (max-width: 991px) {
    padding: 30px 30px 0 30px;
  }
`

export const ContentNewsletter = styled.div`
  margin: 0 auto;
  max-width: 1000px;
  position: relative;
  width: 100%;

  h2 {
    color: var(--primary-color);
    font-family: 'ASICSFont3.0-BoldItalic';
    font-size: 28px;
    margin-bottom: 5px;
    text-align: center;

    @media (max-width: 767px) {
      font-size: 26px;
      line-height: 26px;
    }
  }
`

export const FormNewsletter = styled.div`
  margin: 30px auto;
  width: 60%;

  @media (max-width: 991px) {
    width: 100%;
  }
`
